<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/pvalue_plugin.png"
          alt="p-value logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} p-value
      </template>
      <template #subheader>
        Please configure the following in order to install the p-value plugin.
      </template>
    </PageHeader>

    <div class="section-header">
      Configuration
    </div>

    <CustomDropdown
      v-model="allowedRoles"
      :items="roles"
      header="Allowed Roles"
      multiple
      hint="A list of attendee roles in the import file that are allowed to be processed. Roles not selected will be ignored during import."
    />
    <CustomDropdown
      v-model="allowedStatuses"
      :items="statuses"
      header="Allowed Statuses"
      multiple
      hint="A list of statuses in the import file that are allowed to be processed. Statuses not selected will be ignored during import."
    />

    <validation-observer ref="autoImportConfigs">
      <AutomatedImportConfigurations
        :id="id"
        :plugin-data="pluginData"
        :import-configurations="importConfigurations"
        :import-type="importType"
        :file-extensions="fileExtensionOptions"
        @add-configuration="addImportConfiguration"
        @delete-configuration="deleteImportConfiguration"
        @update-configuration="updateImportValue"
      />
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>
    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="pluginData"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import importExportMixin from "@/utils/import-export-mixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";
import AutomatedImportConfigurations from "@/views/Plugins/components/AutomatedImportConfigurations";

export default {
  name: "AddPluginPvalue",
  metaInfo: {
    title: 'Install p-value'
  },
  components: {
    CustomDropdown,
    PageHeader,
    DeactivateConfirmDialog,
    AutomatedImportConfigurations,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    deleteImportDialog: null,
    deleteImportId: null,
    importConfigurations: [],
    allowedRoles: [],
    allowedStatuses: [],
    pluginData: null,
    checkAllFields: false,
    selectedFileHeaders: [],
    fileExtensionOptions: [
      { id: 'csv', text: 'Comma-Separated Values (.csv)' },
      { id: 'txt', text: 'Text File (.txt)' },
    ],
    roles: [
      { value: 'HCP', label: 'HCP' },
      { value: 'Speaker', label: 'Speaker' },
      { value: 'Employee', label: 'Employee' },
      { value: 'Other', label: 'Other' },
      { value: '', label: '(Blanks)' },
    ],
    statuses: [
      { value: 'Targeted', label: 'Targeted' },
      { value: 'Confirmed', label: 'Confirmed' },
      { value: 'Attended', label: 'Attended' },
      { value: 'No show', label: 'No show' },
      { value: 'Canceled', label: 'Canceled' },
    ],
    importType: 'pvalue_import',
  }),
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.pluginData = {...resp.data};

      this.allowedRoles = resp.data.pluginConfiguration?.allowedRoles ?? [];
      this.allowedStatuses = resp.data.pluginConfiguration?.allowedStatuses ?? [];

      if (resp.data.pluginConfiguration?.importConfigurations) {
        const credentials = resp.data.pluginConfiguration?.credentials;

        this.setupImportConfigurations(resp.data.pluginConfiguration?.importConfigurations, credentials);
      }
    },
    async installPlugin() {
      if (!await this.$refs.autoImportConfigs.validate()) {
        this.$store.commit('snackbar/showMessage', {
          content: 'Please fix the errors in all Import Configurations',
          color: 'error',
        });

        return;
      }

      const pluginConfiguration = this.getFormattedPluginConfiguration(this.importConfigurations);
      pluginConfiguration.allowedRoles = this.allowedRoles;
      pluginConfiguration.allowedStatuses = this.allowedStatuses;

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            const selected_account = this.$store.getters["user/account"];
            this.$store.commit("user/update_selected_account", {
              ...selected_account,
              activePlugins: [...selected_account.activePlugins, resp.data],
            });

            return resp;
          })
          .catch((error) => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    },
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
